<template>
  <div >
    <!-- <van-nav-bar title="授权登录" /> -->
    
  </div>
</template>

<script>
// vue 专用版本JS-SDK
import axios from "axios";
import wx from "weixin-jsapi";
import { weixinScan } from "@api/system";

export default {
  name: "scan",
  data() {
    return {};
  },
  mounted() {
    // 页面加载完成唤醒微信扫一扫
    this.wxScanCode();
  },
  methods: {
    wxScanCode() {
      const rfurl = window.location.href.split("#")[0] 
      // const rfurl ='https://bogejianguan.free.qydev.com'

      const url = encodeURIComponent(rfurl)
      weixinScan({url}).then(res=>{
        if(res.respCode == 200){
          //  console.log("result", resresult);
          // 后端返回的参数
          var timestamp = res.result.timestamp;
          var noncestr = res.result.nonceStr;
          var signature =res.result.signature;
          var appId = res.result.appId;
          wx.config({
            debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            //                                debug : true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timestamp, // 必填，生成签名的时间戳
            nonceStr: noncestr, // 必填，生成签名的随机串
            signature: signature, // 必填，签名，见附录1
            jsApiList: [         
              "scanQRCode"
            ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          wx.ready(function() {
            wx.scanQRCode({
              needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
              scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
              success: function(res) {
                var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                // location.href=res.resultStr;//扫描结果传递到的处理页面,跳转到这个页面
                //   const doctorinfo = result.split("&")[1] 
                //   const doctorId = doctorinfo.split("=")[1] 
                // console.log(result);

                  const urlinfo = result.split("?")[0] 
                  const type = urlinfo.split("#")[1] 
                  const data = result.split("?")[1] 
                  if(type == '/questionbrief'){
                  const doctorId = data.split("=")[1] 
                  // sessionStorage.setItem('doctorId',doctorId);  
                  // location.href = `http://${window.location.host}/#/questionbrief/`;
                  location.href = result;
                  window.localStorage.setItem('questionbrief',location.href);  

                  }else if(type =='/scanresult'){
                    const deviceInfo = data.split("&")[0] 
                    const deviceId = deviceInfo.split("=")[1] 
                  window.localStorage.setItem('deviceId',deviceId);  
                    // location.href = `http://${window.location.host}/#/scanresult/`;
                    location.href = result;

                  }
                  window.localStorage.setItem('from',1)
                  // }else{
                  // }
                  // window.location.href = `http://${window.location.host}/#/questionbrief/`;
                // debugger
                // this.$router.push({name:'questionbrief'})
                // location.href=res.resultStr;//扫描结果传递到的处理页面,跳转到这个页面
                //其它网页调用二维码扫描结果：
                // var result = sessionStorage.getItem("saomiao_result");
              },
              error: function(res) {
                console.log(res);
              }
            });
          });
        }
      })
      
    }
  }
};
</script>
<style lang="less">
.LoginFormWrap{
  padding: 15px;
}
.margin20bott{
  margin-bottom: 20px;
}
</style>